<template>
  <div
    class="csn-rating"
    :class="{ 'csn-rating-mutable': isEditable }"
    :ref="ratingRef"
  >
    <div class="csn-rating-container" :style="ratingStyle">
      <RatingFullIcon />
      <RatingFullIcon />
      <RatingFullIcon />
      <RatingFullIcon />
      <RatingFullIcon />
      <RatingHalfIcon v-if="showsHalf" />
      <RatingEmptyIcon />
      <RatingEmptyIcon />
      <RatingEmptyIcon />
      <RatingEmptyIcon />
      <RatingEmptyIcon />
    </div>
  </div>
</template>
<script>
import { EventType, Digit, RATING_STARTS } from '@/constants'

const RATING_REF = 'ratingRef'
const initialHoverValue = Digit.NOUGHT
const CHANGE = 'change'

const defineValue = (v) => {
  if (v <= Digit.TEN) {
    return Digit.NOUGHT_FIVE
  }
  if (v <= Digit.TWENTY) {
    return Digit.ONE
  }
  if (v <= Digit.THIRTY) {
    return Digit.ONE_FIVE
  }
  if (v <= Digit.FORTY) {
    return Digit.TWO
  }
  if (v <= Digit.FIFTY) {
    return Digit.TWO_FIVE
  }
  if (v <= Digit.SIXTY) {
    return Digit.THREE
  }
  if (v <= Digit.SEVENTY) {
    return Digit.THREE_FIVE
  }
  if (v <= Digit.EIGHTY) {
    return Digit.FOUR
  }
  if (v <= Digit.NINETY) {
    return Digit.FOUR_FIVE
  }
  return Digit.FIVE
}
const defineTranslateX = (hoverValue) => {
  switch (hoverValue) {
    case Digit.NOUGHT_FIVE:
      return -Digit.ONE_HUNDRED
    case Digit.ONE:
      return -Digit.EIGHTY
    case Digit.ONE_FIVE:
      return -Digit.EIGHTY
    case Digit.TWO:
      return -Digit.SIXTY
    case Digit.TWO_FIVE:
      return -Digit.SIXTY
    case Digit.THREE:
      return -Digit.FORTY
    case Digit.THREE_FIVE:
      return -Digit.FORTY
    case Digit.FOUR:
      return -Digit.TWENTY
    case Digit.FOUR_FIVE:
      return -Digit.TWENTY
    case Digit.FIVE:
      return Digit.NOUGHT
    default:
      Digit.NOUGHT
      return -Digit.ONE_HUNDRED
  }
}
export default {
  name: RATING_STARTS,
  data: () => ({
    hoverValue: initialHoverValue,
    showsHoverValue: false,
    containerX: null,
  }),
  components: {
    RatingEmptyIcon: () => import('@/components/svg/RatingEmptyIcon'),
    RatingHalfIcon: () => import('@/components/svg/RatingHalfIcon'),
    RatingFullIcon: () => import('@/components/svg/RatingFullIcon'),
  },
  props: {
    isEditable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String],
      default: Digit.NOUGHT,
    },
  },
  computed: {
    ratingRef: () => RATING_REF,
    ratingStyle() {
      const value = this.showsHoverValue
        ? defineTranslateX(this.hoverValue)
        : defineTranslateX(this.value)
      return {
        transform: `translateX(${value}px)`,
      }
    },
    showsHalf() {
      if (this.showsHoverValue) {
        return this.hoverValue !== Math.floor(this.hoverValue)
      }
      return this.value !== Math.floor(this.value)
    },
  },
  methods: {
    handleEnter() {
      if (!this.isEditable) {
        return
      }
      if (!this.showsHoverValue) {
        this.showsHoverValue = true
      }
    },
    handleLeave() {
      if (!this.isEditable) {
        return
      }
      if (this.showsHoverValue) {
        this.showsHoverValue = false
      }
    },
    handleMove({ x }) {
      if (!this.isEditable) {
        return
      }
      const xDistanceWithinContainer = x - this.containerX
      const newValue = defineValue(xDistanceWithinContainer)
      if (this.hoverValue !== newValue) {
        this.hoverValue = newValue
      }
    },
    handleClick() {
      if (!this.isEditable) {
        return
      }
      this.$emit(CHANGE, this.hoverValue)
    },
    setContainerX() {
      this.containerX = this.$refs[RATING_REF].getBoundingClientRect().x
    },
    addListeners() {
      this.$refs[RATING_REF].addEventListener(
        EventType.MOUSEENTER,
        this.handleEnter,
      )
      this.$refs[RATING_REF].addEventListener(
        EventType.MOUSELEAVE,
        this.handleLeave,
      )
      this.$refs[RATING_REF].addEventListener(
        EventType.MOUSEMOVE,
        this.handleMove,
      )
      this.$refs[RATING_REF].addEventListener(EventType.CLICK, this.handleClick)
      this.setContainerX()
      window.addEventListener(EventType.RESIZE, this.setContainerX)
    },
    removeListeners() {
      this.$refs[RATING_REF].addEventListener(
        EventType.MOUSEENTER,
        this.handleEnter,
      )
      this.$refs[RATING_REF].removeEventListener(
        EventType.MOUSELEAVE,
        this.handleLeave,
      )
      this.$refs[RATING_REF].removeEventListener(
        EventType.MOUSEMOVE,
        this.handleMove,
      )
      this.$refs[RATING_REF].removeEventListener(
        EventType.CLICK,
        this.handleClick,
      )
      window.removeEventListener(EventType.RESIZE, this.setContainerX)
    },
  },
  mounted() {
    this.addListeners()
  },
  beforeDestroy() {
    this.removeListeners()
  },
}
</script>
